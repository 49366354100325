import * as yup from "yup";

export const SignupInputSchema = yup.object().shape({
  email: yup
    .string()
    .email("please enter a valid email")
    .required("please enter your email"),
  password: yup.string().required("please enter a password"),
  name: yup.string().required("Please enter your full name "),
});
