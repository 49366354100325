import { useForm } from "@conform-to/react";
import { getYupConstraint, parseWithYup } from "@conform-to/yup";
import {
  Form,
  useActionData,
  useNavigation,
  useSearchParams,
} from "@remix-run/react";
import { Button, Flex, TextField } from "gestalt";
import { useEffect, useId, useRef } from "react";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import type { action } from ".";
import { analytics } from "../../utils/analytics.client";
import { UTM_FRIST, UTM_LAST } from "../../utils/constats";
import { SignupInputSchema } from "./types";

export const SignupForm = () => {
  const [searchParams] = useSearchParams();
  const suggestedEmail = searchParams.get("email");
  const suggestedName = searchParams.get("name");
  const navigation = useNavigation();
  const lastResult = useActionData<typeof action>();

  const loading =
    (navigation.state === "loading" || navigation.state === "submitting") &&
    navigation.formData?.get("action") === "signup";

  const emailRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const id = useId();
  const [form, { email, name, password }] = useForm({
    lastResult,
    constraint: getYupConstraint(SignupInputSchema),
    onValidate({ formData }) {
      return parseWithYup(formData, { schema: SignupInputSchema });
    },
    id,
    defaultValue: {
      email: suggestedEmail,
      name: suggestedName,
    },
    onSubmit(_, context) {
      const email = context.formData.get("email") as string;
      const name = context.formData.get("name") as string;

      const utmFirst = localStorage.getItem(UTM_FRIST);
      const utmLast = localStorage.getItem(UTM_LAST);

      analytics.identify(email, {
        email,
        name,
        ...JSON.parse(utmFirst ?? ""),
        ...JSON.parse(utmLast ?? ""),
      });
      analytics.track("User Signup");
    },
  });

  useEffect(() => {
    if (suggestedEmail && emailRef.current) {
      emailRef.current.value = suggestedEmail;
    }
    if (suggestedName && nameRef.current) {
      nameRef.current.value = suggestedName;
    }
  }, [suggestedEmail, suggestedName]);

  return (
    <Form method="POST" id={form.id} onSubmit={form.onSubmit}>
      <input hidden name="action" defaultValue="signup" />
      <Flex direction="column" gap={6}>
        <TextField
          id={name.id ?? ""}
          placeholder="First name"
          type="text"
          name={name.name}
          label="Full Name*"
          onChange={() => undefined}
          size="lg"
          errorMessage={name.errors}
          ref={nameRef}
        />

        <TextField
          id={email.id ?? ""}
          placeholder="you@company.com"
          type="email"
          name={email.name}
          label="Work email*"
          autoComplete="email"
          errorMessage={email.errors || form.errors}
          size="lg"
          onChange={() => undefined}
          ref={emailRef}
        />
        <TextField
          id={password.id ?? ""}
          placeholder="Enter a strong password"
          type="password"
          name={password.name}
          label="Create a password"
          autoComplete="new-password"
          onChange={() => undefined}
          size="lg"
          errorMessage={password.errors}
        />
        <HoneypotInputs key="homeypot" />
        <Button
          type="submit"
          fullWidth={true}
          size="lg"
          color="blue"
          text="Sign up"
          disabled={loading}
        />
      </Flex>
    </Form>
  );
};
