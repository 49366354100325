import { Box, ButtonLink, Divider, Flex, Link, Text } from "gestalt";

import { parseWithYup } from "@conform-to/yup";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import { AuthorizationError } from "remix-auth";
import BigContainer from "../../components/BigContainer";
import Footer from "../../components/Footer";
import { ErrorBoundary } from "../../components/GeneralErrorBoundary";
import RetentionLogo from "../../components/RetentionLogo";
import { authenticator } from "../../utils/auth.server";
import { validateHoneypot } from "../../utils/honeypot.server";
import { SignupForm } from "./SignupForm";
import { SignupInputSchema } from "./types";
export { ErrorBoundary };

export const meta: MetaFunction = () => {
  return [
    {
      title: "Sign Up | Retention Script",
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  return await authenticator.isAuthenticated(request, {
    successRedirect: "/",
  });
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.clone().formData();
  try {
    validateHoneypot(formData);
    return await authenticator.authenticate("user-pass-signup", request, {
      successRedirect: "/verify-email",
      throwOnError: true,
    });
  } catch (error) {
    if (error instanceof Response) return error;
    if (error instanceof AuthorizationError) {
      if (error.message === "user already exist") {
        const submission = parseWithYup(formData, {
          schema: SignupInputSchema,
        });
        return json(
          submission.reply({
            formErrors: ["Email exist, try signing in"],
          })
        );
      }
    }
    return {};
  }
}

export default function Component() {
  return (
    <BigContainer>
      <Box
        display="flex"
        justifyContent="between"
        padding={2}
        alignItems="center"
      >
        <RetentionLogo />
        <ButtonLink text="Sign in" href="/sign-in" color="transparent" />
      </Box>
      <Divider />
      <Header />
      <Content />
      <Box marginBottom={4} marginTop={12}>
        <Divider />
      </Box>
      <Box paddingY={0} paddingX={2} marginBottom={8} as="footer">
        <Footer />
      </Box>
    </BigContainer>
  );
}
const Header = () => {
  return (
    <Box marginTop={12} marginBottom={12}>
      <Text weight="bold" size="500" align="center">
        Get started with your Retention Script account
      </Text>
      <Text weight="bold" size="500" align="center"></Text>

      <Box marginTop={3} />
      <Text size="400" align="center">
        Run scripts on user interactions
      </Text>
    </Box>
  );
};

const Content = () => {
  return (
    <Box height="100%" width="100%">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap={{ row: 0, column: 8 }}
        height="100%"
      >
        <SignupForm />
        <PrivacyText />
      </Flex>
    </Box>
  );
};

const PrivacyText = () => {
  return (
    <Flex alignItems="center" justifyContent="center" maxWidth={350}>
      <Text align="center" color="subtle">
        By clicking "Sign up" you agree to our{" "}
        <Link
          display="inlineBlock"
          href="https://retentionscript.com/terms-of-service"
        >
          Terms of Service{" "}
        </Link>{" "}
        and{" "}
        <Link
          href="https://retentionscript.com/privacy-policy"
          display="inlineBlock"
        >
          {" "}
          Privacy Policy
        </Link>
      </Text>
    </Flex>
  );
};
